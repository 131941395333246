import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import AOS from "aos";

import "assets/css/style.css";
import heroImg from "assets/images/home.png";
import boilerplate from "assets/images/logo.png";
import app_store_img from "assets/images/app_store.svg";
import play_store_img from "assets/images/play_store.svg";
import footerImg from "assets/images/footer-img.png";
import { useGetAllFeatureData } from "hooks";
import { CounterSection, FeatureList, HeroBanner, SeoHeader } from "common";

function Main({ getSettingData, t }) {
  const location = useLocation();
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const { data: getAllFeatureData } = useGetAllFeatureData(
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );

  const featureList = getAllFeatureData?.data?.feature_list || [];

  useEffect(() => {
    if (location.hash === "#download") {
      scroller.scrollTo("download", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location.hash]);

  const counters = [
    {
      end: 5.3,
      unit: "K+",
      title:
        getSettingData?.data?.home_setting_home_banner_job_vacancy_title ||
        t("front_page.home_setting_home_banner_job_vacancy_title"),
    },
    {
      end: 1,
      unit: "M+",
      title:
        getSettingData?.data?.home_setting_home_banner_registered_users_title ||
        t("front_page.home_setting_home_banner_registered_users_title"),
    },
    {
      end: 5.5,
      unit: "K+",
      title:
        getSettingData?.data?.home_setting_home_banner_companies_count_title ||
        t("front_page.home_setting_home_banner_companies_count_title"),
    },
  ];

  return (
    <>
      <SeoHeader title={"Main"} description={"Main"} />
      <HeroBanner
        title={
          getSettingData?.data?.home_setting_home_banner_title ||
          t("front_page.home_setting_home_banner_title_text_static")
        }
        description={
          getSettingData?.data?.home_setting_home_banner_description ||
          t("front_page.home_setting_home_banner_description_text_static")
        }
        buttonText={
          getSettingData?.data?.home_setting_home_banner_get_touch_btn_txt ||
          t("front_page.home_setting_home_banner_button_text_static")
        }
        buttonLink={
          getSettingData?.data?.home_setting_home_banner_get_touch_btn_link ||
          "#"
        }
        imageUrl={getSettingData?.data?.home_setting_home_banner_image}
        defaultImage={heroImg}
      />

      {/* counter */}

      <CounterSection counters={counters} />

      {/* Main features */}
      <FeatureList
        features={featureList}
        title="front_page.main_feature_label"
        t={t}
      />
      {/* Banner */}

      <Container fluid className="mt-5">
        <Row className="banner-bg pt-5 pb-5 px-4 px-lg-5 overflow-hidden">
          <Col md={12} lg={9} className="banner-title" data-aos="fade-right">
            <h2>
              {getSettingData?.data?.home_setting_job_post_title ||
                t("front_page.home_setting_home_jop_post_title_text_static")}
            </h2>
            <p className="mt-2">
              {getSettingData?.data?.home_setting_seo_description}
            </p>
          </Col>
          <Col
            lg={3}
            className="d-flex align-items-center justify-content-lg-end justify-content-start job-post"
            data-aos="fade-left"
          >
            <Button
              size="xl"
              className="px-4 px-md-5 py-2 py-md-3 bg-black border-0 jp-btn"
              onClick={() =>
                (window.location.href =
                  getSettingData?.data?.home_setting_job_post_btn_link)
              }
            >
              {getSettingData?.data?.home_setting_job_post_btn_txt ||
                t("front_page.home_setting_home_job_button_text_static")}
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mt-5 overflow-hidden" id="download">
        <Row data-aos="slide-left">
          <Col
            sm={12}
            lg={11}
            className="ms-auto download-div d-flex align-items-center justify-content-center"
          >
            <Row className="d-flex justify-content-center align-items-center bg-row w-100">
              <Col sm={5} className="bg-body jm-div">
                <img
                  style={{ maxWidth: "20rem" }}
                  src={
                    getSettingData?.data?.home_setting_home_download_image ||
                    boilerplate
                  }
                ></img>
              </Col>
              <Col sm={6} className="download-text mt-3 mt-sm-0 p-sm-3 p-md-5">
                <h1>
                  {getSettingData?.data?.home_setting_home_download_title}
                </h1>
                <p>
                  {getSettingData?.data?.home_setting_home_download_description}{" "}
                </p>
                <div className="d-flex flex-md-row store-btn">
                  <a
                    href={
                      getSettingData?.data
                        ?.home_setting_home_download_app_store_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-download mb-3 mb-md-0 me-md-3 d-flex align-items-center"
                  >
                    <img
                      src={
                        getSettingData?.data
                          ?.home_setting_home_download_app_store_image ||
                        app_store_img
                      }
                      className="pe-3 img-fluid"
                      alt="app-store"
                    />
                    <span className="right">
                      <span className="m-0 d-block download-btn-title">
                        {getSettingData?.data
                          ?.home_setting_home_download_app_store_title ||
                          t(
                            "front_page.home_setting_home_download_app_store_title_static"
                          )}
                      </span>
                      <strong className="download-btn-subtitle">
                        {
                          getSettingData?.data
                            ?.home_setting_home_download_app_store_subtitle
                        }
                      </strong>
                    </span>
                  </a>
                  <a
                    href={
                      getSettingData?.data
                        ?.home_setting_home_download_play_store_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-download d-flex align-items-center"
                  >
                    <img
                      src={
                        getSettingData?.data
                          ?.home_setting_home_download_play_store_image ||
                        play_store_img
                      }
                      className="pe-3 img-fluid"
                      alt="play-store"
                    />
                    <span className="right">
                      <span className="m-0 d-block download-btn-title">
                        {
                          getSettingData?.data
                            ?.home_setting_home_download_play_store_subtitle
                        }
                      </span>
                      <strong className="download-btn-subtitle">
                        {getSettingData?.data
                          ?.home_setting_home_download_play_store_title ||
                          t(
                            "front_page.home_setting_home_download_play_store_title_static"
                          )}
                      </strong>
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Join us */}
      <h1 className="join-us-heading text-center px-5" data-aos="fade">
        {getSettingData?.data?.home_setting_footer_title}
      </h1>
      <Container fluid className="p-0 overflow-hidden">
        <div className="w-100 footer-img mt-4 mt-md-5" data-aos="fade-up">
          <img
            src={getSettingData?.data?.home_setting_footer_image || footerImg}
          ></img>
        </div>
      </Container>
    </>
  );
}
Main.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
export default Main;
