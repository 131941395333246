import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./PublicRoute";
import Main from "pages/Homepage/Main";
import About from "pages/AboutPage/About";
import TermsAndConditions from "pages/Terms&Conditions/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import Contact from "pages/ContactPage/Contact";
import Faq from "pages/FAQ/Faq";
import "locales/i18n";
import { useGetSettingData } from "hooks";
import CmsRoutes from "pages/CmsPage/CmsRoutes";
import { PageNotFound } from "common/components/PageNotFound";

const PagesRoutes = ({ t }) => {
  const location = useLocation();

  // Determine route name based on location.pathname
  const route_name =
    location.pathname === "/" ||
    location.pathname.startsWith("/page/") ||
    location.pathname.startsWith("/company")
      ? "home"
      : location.pathname.substr(1);

  const { data: getSettingData } = useGetSettingData(
    route_name,
    () => {},
    (error) => {
      if (error.data.redirect == true) {
        console.log("Error occurred: ", error.message);
      }
    }
  );

  var pageTitle = t("front_page.default_page_title_label");
  var seoTitle = getSettingData?.data?.home_setting_seo_title;
  var seoDescription = getSettingData?.data?.home_setting_seo_description;
  if (location.pathname == "/faq") {
    pageTitle = t("front_page.faq_page_title_label");
    seoTitle = getSettingData?.data?.faq_setting_seo_title;
    seoDescription = getSettingData?.data?.faq_setting_seo_description;
  } else if (location.pathname == "/contact") {
    pageTitle = t("front_page.contact_us_page_title_label");
    seoTitle = getSettingData?.data?.contact_setting_seo_title;
    seoDescription = getSettingData?.data?.contact_setting_seo_description;
  } else if (location.pathname == "/about") {
    pageTitle = t("front_page.about_page_title_label");
    seoTitle = getSettingData?.data?.about_setting_seo_title;
    seoDescription = getSettingData?.data?.about_setting_seo_description;
  }
  return (
    <>
      {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <link
          rel="icon"
          type="image/x-icon"
          href={`${getSettingData?.data?.home_page_general_favicon_logo}`}
        />
      </Helmet> */}
      <Routes>
        <Route element={<PublicRoute getSettingData={getSettingData} t={t} />}>
          <Route
            exact
            path="/"
            element={<Main getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/about"
            element={<About getSettingData={getSettingData} t={t} />}
          />
          <Route path="/page/*" element={<CmsRoutes t={t} />} />
          <Route
            exact
            path="/terms-conditions"
            element={
              <TermsAndConditions getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicy getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/contact"
            element={<Contact getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/faq"
            element={<Faq getSettingData={getSettingData} t={t} />}
          />
        </Route>
        <Route>
          <Route
            path="*"
            element={<PageNotFound getSettingData={getSettingData} t={t} />}
            from="front"
          />{" "}
        </Route>
      </Routes>
    </>
  );
};

PagesRoutes.propTypes = {
  t: PropTypes.func,
};

export default PagesRoutes;
