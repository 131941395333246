import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { settingData } from "store/features/settingSlice";

const SeoHeader = ({ title, description }) => {
  const getSettingData = useSelector(settingData);
  const titles = "Boilerplate" + " | " + title;

  return (
    <Helmet>
      <title>{titles}</title>
      <meta name="title" content={titles} />
      <meta name="description" content={description} />
      <meta property="og:title" content={titles} />
      <meta property="og:description" content={description} />
      <link
        rel="icon"
        type="image/x-icon"
        href={`${getSettingData?.data?.home_page_general_favicon_logo}`}
      />
    </Helmet>
  );
};
SeoHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SeoHeader;
